<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <!-- creationDateUtc -->
        <b-col md="3">
          <g-picker
            :value.sync="selectedItem.creationDateUtc"
            label-text="creationDate"
            disabled
          />
        </b-col>
        <!-- additionalUserPrice -->
        <b-col md="3">
          <g-field
            label-text="additionalUserPrice"
            field="number"
            rules="required"
            :value.sync="selectedItem.additionalUserPrice"
            type="number"
          />
        </b-col>
        <!-- additionalBranchPrice -->
        <b-col md="3">
          <g-field
            label-text="additionalBranchPrice"
            field="number"
            rules="required"
            :value.sync="selectedItem.additionalBranchPrice"
            type="number"
          />
        </b-col>
        <!-- additionalInvoicePrice -->
        <b-col md="3">
          <g-field
            label-text="incorporationPrice"
            field="number"
            rules="required"
            :value.sync="selectedItem.incorporationPrice"
            type="number"
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- additionalItemPrice -->
        <!-- <b-col md="3">
          <g-field
            label-text="additionalItemPrice"
            field="number"
            rules="required"
            :value.sync="selectedItem.additionalItemPrice"
            type="number"
          />
        </b-col> -->
        <!-- taxPercentage -->
        <b-col md="3">
          <g-field
            label-text="taxPercentage"
            field="number"
            rules="required|min_value:0|max_value:100"
            :value.sync="selectedItem.taxPercentage"
            type="number"
          />
        </b-col>
        <b-col md="3">
          <b-form-group class="d-inline" :label="$t('isActive')">
            <b-form-checkbox
              v-model="selectedItem.isActive"
              class="mr-0 mt-50"
              name="is-rtl"
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
          >
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      selectedItem: {
        additionalUserPrice: 0,
        additionalBranchPrice: 0,
        additionalInvoicePrice: 0,
        additionalItemPrice: 0,
        // taxPercentage: 0,
        isActive: true,
        creationDateUtc: this.today,
      },
    };
  },
  computed: {},
  mounted() {
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.get({ url: 'PricingPolicy', id: this.id }).then((data) => {
        this.selectedItem = data;
      });
    },
    save(type) {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'PricingPolicy',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'pricing-policy' });
        });
      } else {
        this.create({
          url: 'PricingPolicy',
          data: this.selectedItem,
        }).then((data) => {
          this.doneAlert({
            text: this.$t('savedSuccessfully'),
          });
          this.$router.push({ name: 'pricing-policy' });
        });
      }
    },
  },
};
</script>

<style></style>
